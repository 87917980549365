import axios from "axios";
import ProgressSmoother, {ProgressSmootherConfig} from "@/utils/ProgressSmoother";
import {Job, JobDetails} from "@/types";
import {ExtractionMethod} from "@/types/enums";
import InsufficientCreditsException from "@/exceptions/InsufficientCreditsException";
import jobUpdateListener, {JobUpdate} from "@/services/JobUpdateListener";

export type ExtractionJobListener = {
    disconnect: () => void
}

export enum ExtractionJobStatus {
    Submitted = 'SUBMITTED',
    Runnable = 'RUNNABLE',
    Starting = 'STARTING',
    Running = 'RUNNING',
    Failed = 'FAILED',
    Terminated = 'TERMINATED',
    Succeeded = 'SUCCEEDED',
    Cancelled = 'CANCELLED',
}

export namespace ExtractionJobStatus {

    export function isRunning(status: ExtractionJobStatus) {
        return status === ExtractionJobStatus.Runnable ||
            status === ExtractionJobStatus.Running ||
            status === ExtractionJobStatus.Starting ||
            status === ExtractionJobStatus.Submitted;
    }

    export function isStarting(status: ExtractionJobStatus) {
        return [ExtractionJobStatus.Runnable, ExtractionJobStatus.Submitted].includes(status);
    }
}

export type JobStartResponse = {
    id: string
}

const JOB_STARTUP_SECONDS = 100;
const JOB_STARTUP_PERCENT_PROGRESS = 70;
const BASELINE_VIDEO_DURATION = 60;
const AVG_VALUE_INCREASE_DELTA = 10;

const smoothingConfigOcr: ProgressSmootherConfig = {
    maxValue: 100,
    averageTimeBetweenValues: 20_000,
    averageValueIncreaseDelta: AVG_VALUE_INCREASE_DELTA,
    delayUntilFirstValue: JOB_STARTUP_SECONDS * 1000,
    firstValue: JOB_STARTUP_PERCENT_PROGRESS,
}

const smoothingConfigAudio: ProgressSmootherConfig = {
    maxValue: 100,
    averageTimeBetweenValues: 10_000,
    averageValueIncreaseDelta: 10,
    delayUntilFirstValue: 125 * 1000,
    firstValue: 70,
}

export type ListenerConfig = {
    on_update: (progress: number) => void,
    on_error: (error: Error) => void
    job_name: string,
    video_duration: number,
    updates_per_second?: number,
    started_at?: number,
    method: ExtractionMethod
}

export function listenForExtractionJobUpdates({
    on_update,
    on_error,
    job_name,
    method,
    video_duration,
    started_at,
    updates_per_second = 2,
}: ListenerConfig): ExtractionJobListener {

    let progressSmoother: ProgressSmoother
    let interval: NodeJS.Timeout

    const initProgress = () => {
        if (!progressSmoother) {
            const config = makeSmoothingConfig(video_duration, method);
            progressSmoother = new ProgressSmoother({...config, started_at});
        }
    }

    const messageSubscriber = {
        onMessage(message: JobUpdate) {

            if (message.type !== "progress" && message.type !== "status") {
                return
            }

            switch (message.status) {
                case ExtractionJobStatus.Running:
                case ExtractionJobStatus.Starting:
                    initProgress();
                    break;
                case ExtractionJobStatus.Failed:
                    on_error(new Error("Extraction failed, please try again later."))
                    break;
                case ExtractionJobStatus.Succeeded:
                    initProgress();
                    message = {type: 'progress', progress: 100}
                    break;
                default:
                    break;
            }

            if (message.type === "progress") {
                progressSmoother?.setValue(message.progress * 100);
            }
        },
        onError(error: Error) {
            console.error(error)
        }
    }

    jobUpdateListener.subscribe(job_name, messageSubscriber).then(() => {
        interval = setInterval(() => {
            if (progressSmoother) {
                on_update(progressSmoother.smoothedValue())
            }
        }, 1000 / updates_per_second);
        jobUpdateListener.requestProgress(job_name);
    }).catch(on_error)

    return {
        disconnect: function () {
            clearInterval(interval)
            jobUpdateListener.unsubscribe(job_name, messageSubscriber)
        }
    };
}

function makeSmoothingConfig(videoDuration: number, method: ExtractionMethod): ProgressSmootherConfig {
    const template = method == "ocr" ? smoothingConfigOcr : smoothingConfigAudio;
    let config = {...template};
    if (!videoDuration) {
        return config;
    }
    const durationMinutes = videoDuration / 60;
    const durationFactor = 1 / (durationMinutes / BASELINE_VIDEO_DURATION);
    config.averageValueIncreaseDelta = Math.min(durationFactor, 1) * config.averageValueIncreaseDelta;
    config.firstValue = Math.min(durationFactor, 1) * config.firstValue;
    return config;
}

export function cancelJob(job: Job) {
    return axios.put(route('job.cancel', job.id)).catch(err => {
        console.error(err)
    })
}

export function deleteJob(job: Job) {
    return axios.delete(route('job.delete', job.id)).catch(err => {
        console.error(err)
    })
}

export async function getVideoDuration(id: string): Promise<number> {
    try {
        const res = await axios.get(route('upload.validate', {id}))
        return res.data.duration
    } catch (e: any) {
        const errorMessage = e.response?.data?.message || e.message
        throw new Error(errorMessage)
    }
}

export async function startJob(details: JobDetails): Promise<JobStartResponse> {
    try {
        const methodParams = details.extractionParams || {};
        const metadata = Object.assign(details.metaData || {}, methodParams);
        const result = await axios.post(route('startJob', {
            jobName: details.id,
            language: details.language,
            fileName: details.fileName,
            method: details.method,
            methodParams: JSON.stringify(methodParams),
            metadata: JSON.stringify(
                metadata
            )
        }));
        return result.data;
    } catch (err: any) {
        if (err.response) {
            const data = err.response?.data || {};
            const msg = data.message ? data.message : JSON.stringify(data);
            if (data?.type === "insufficient_credits") {
                throw (new InsufficientCreditsException(data.balance));
            }
            throw new Error(msg);
        } else {
            throw err;
        }
    }
}
