import {PropsWithChildren} from 'react';
import {Toaster} from "react-hot-toast";
import Header from "@/Layouts/Guest/Header";
import Footer from "@/Layouts/Guest/Footer";
import {usePage} from "@inertiajs/react";
import {PageProps} from "@/types";
import useSessionToast from "@/utils/useSessionToast";

export default function GuestLayout({children}: PropsWithChildren) {
    const {sessionFlash} = usePage<PageProps>().props

    useSessionToast(sessionFlash);
    return (
        <>
            <div
                className="relative sm:items-center min-h-[70vh]  bg-center bg-white selection:bg-orange-500 selection:text-white">
                <Header/>
                {children}
            </div>
            <div className="xl:px-10">
                <Footer/>
            </div>
            <Toaster/>
        </>
    );
}
