import {useDropzone} from 'react-dropzone';
import {useEffect, useState} from "react";

import {bytesToSize} from "@/utils/textFormatting";
import Popover from "@/Components/Popover";
import SocialMediaIcons from "@/Pages/Dashboard/Partials/SocialMediaIcons";

interface FileInputProps {
    onFilesSelected: (file: File[]) => void;
    disabled?: boolean;
    maxSize?: number;
    onFileSizeExceeded?: (msg: string) => void;
    onSelectUrl?: () => void;
    canSelectUrl?: boolean;
    multiple?: boolean;
    maxFiles?: number;
}

export default function MediaFileInput({
    onFilesSelected, maxSize, onFileSizeExceeded,
    multiple, maxFiles, onSelectUrl, canSelectUrl
}: FileInputProps) {
    const [isDragActive, setIsDragActive] = useState(false);
    const {acceptedFiles, fileRejections, getRootProps, getInputProps, open} = useDropzone({
        multiple: !!multiple,
        accept: {
            'video/*': [],
            'audio/*': [],
            'text/plain': ['.srt', '.vtt', '.sup', '.ass']
        },
        onDragEnter: () => setIsDragActive(true),
        onDragLeave: () => setIsDragActive(false),
        onDropAccepted: () => setIsDragActive(false),
        onDropRejected: () => setIsDragActive(false),
        maxSize: maxSize,
        maxFiles: maxFiles || undefined,
        noClick: true,
    })

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            if (!multiple) {
                onFilesSelected([acceptedFiles[0]]);
            } else {
                onFilesSelected(acceptedFiles);
            }
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections.length > 0) {
            const code = fileRejections[0].errors[0].code;
            if (code === 'file-too-large') {
                onFileSizeExceeded?.(`File size must be less than ${bytesToSize(maxSize || 0)}`)
            }
        }
    }, [fileRejections]);

    return (
        <div {...getRootProps({className: 'w-full  dropzone flex justify-center'})}>
            <div className="flex items-center justify-center grow mb-5">
                <label htmlFor="dropzone-file"
                       className={`grow transition-all w-full text-center ${isDragActive ? 'opacity-30' : ''}`}>
                    <div className="pre-upload flex flex-col justify-center ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="mx-auto text-gray-500 inline-block w-10 h-10 bi bi-cloud-arrow-up"
                             viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"></path>
                            <path
                                d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"></path>
                        </svg>
                        <div className="py-3"><span>Drag & drop file here</span></div>
                    </div>
                    <div className="pre-upload text-center mt-4">
                        <button
                            onClick={open}
                            className={`py-1.5 px-3 inline-block text-center rounded leading-normal text-gray-800 bg-gray-100 border border-gray-100 hover:text-gray-900 hover:bg-gray-200 hover:ring-0 hover:border-gray-200 focus:bg-gray-200 focus:border-gray-200 focus:outline-none focus:ring-0 mr-2`}>
                            Browse file
                        </button>
                        {
                            !canSelectUrl ?
                                <Popover arrowClassName="!bg-gray-50" placement="bottom" trigger="hover"
                                         content={<ImportUrlPopover/>}>
                                    <button
                                        className={`py-1.5 px-3 cursor-default inline-block text-center rounded leading-normal text-gray-800  border border-gray-300 hover:border-gray-400 ${!onSelectUrl ? 'hidden' : ''}`}
                                        onClick={() => canSelectUrl && onSelectUrl && onSelectUrl()}>
                                        Import from URL
                                    </button>
                                </Popover>
                                :
                                <button
                                    className="py-1.5 px-3 inline-block text-center rounded leading-normal text-gray-800  border border-gray-300 hover:text-gray-900 hover:bg-gray-200 hover:ring-0 hover:border-gray-200 focus:bg-gray-200 focus:border-gray-200 focus:outline-none focus:ring-0"
                                    onClick={onSelectUrl}>
                                    Import from URL
                                </button>
                        }

                    </div>

                </label>
            </div>

            <input {...getInputProps()} />
        </div>
    );
}

function ImportUrlPopover({
        youtube = laravelConfig('app.youtube_import_enabled', true)
    }: { youtube?: boolean }
) {
    return (
        <div className="px-4 pt-4 w-46 bg-gray-50">
            <SocialMediaIcons/>
            <span
                className="inline-block mt-2 mb-3 text-sm">Upgrade to a paid account by purchasing credits<br/> to unlock this and other premium features.
            </span>
            {
                !youtube && (
                    <div className="pb-3">
                        <div
                            className="p-4 pb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                            role="alert">
                            <b>Note:</b> <span>Youtube is currently not working, but we are working on a fix.</span>
                        </div>
                    </div>
                )
            }


        </div>
    )
}
