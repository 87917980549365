import {useForm} from "@inertiajs/react";
import InputLabel from "@/Components/InputLabel";
import TextInput from "@/Components/TextInput";
import InputError from "@/Components/InputError";
import {PageProps, User} from "@/types";
import toast from "react-hot-toast";

export default function ContactForm({user}: { user?: User }) {
    const {data, setData, post, processing, errors, recentlySuccessful} = useForm({
        email: user?.email || '',
        name: user?.name || '',
        subject: '',
        message: '',
    })

    const subjects = [
        'Technical Support',
        'Feature Request',
        'Bug Report',
        'Account Issues',
        'Billing Questions',
        'Other'
    ];

    function handleSubmit(e: any) {
        e.preventDefault()
        post(route('contact'), {
            preserveScroll: true,
            onSuccess: ({props}) => {
                if(!(props as unknown as PageProps).sessionFlash.error) {
                    setData('message', '')
                }
            }
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="mt-6 space-y-6">
                <div>
                    <InputLabel htmlFor="name" value="Name"/>
                    <TextInput
                        id="name"
                        className="mt-1 block w-full"
                        value={data.name}
                        onChange={(e) => setData('name', e.target.value)}
                        required
                        autoComplete="name"
                    />
                    <InputError className="mt-2" message={errors.name}/>
                </div>

                <div>
                    <InputLabel htmlFor="email" value="Email"/>
                    <TextInput
                        id="email"
                        className="mt-1 block w-full"
                        value={data.email}
                        onChange={(e) => setData('email', e.target.value)}
                        required
                        autoComplete="email"
                    />
                    <InputError className="mt-2" message={errors.email}/>
                </div>

                <div>
                    <InputLabel htmlFor="subject" value="Subject"/>
                    <select
                        id="subject"
                        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        value={data.subject}
                        onChange={(e) => setData('subject', e.target.value)}
                        required
                    >
                        <option value="">Select a subject</option>
                        {subjects.map((subject) => (
                            <option key={subject} value={subject}>
                                {subject}
                            </option>
                        ))}
                    </select>
                    <InputError className="mt-2" message={errors.subject}/>
                </div>

                <div>
                    <InputLabel htmlFor="message" value="Message"/>
                    <textarea
                        id="message"
                        rows={6}
                        className="block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Enter your message..."
                        value={data.message}
                        onChange={(e) => setData('message', e.target.value)}
                        required
                    />
                    <InputError className="mt-2" message={errors.message}/>
                </div>

                <div className="flex items-center gap-4">
                    <button type="submit"
                            disabled={processing || recentlySuccessful}
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 sm:w-fit focus:ring-4 focus:outline-none focus:ring-primary-300">
                        Send message
                    </button>
                </div>
            </form>
        </>
    )
}
