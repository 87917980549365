import { ExtractionMethod, TranslationMethod } from "@/types/enums";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getLanguageName } from "@/utils/textFormatting";

export const DEFAULT_LANGUAGE = 'auto';

type ServiceType = 'extraction' | 'translation';
type Method = ExtractionMethod | TranslationMethod;

type LanguageResponse = {
    [key in ExtractionMethod | TranslationMethod]: string[]
} & {
    common: string[]
};

type LanguageOption = {
    label: string;
    value: string;
};

async function getSupportedLanguages(type: ServiceType): Promise<LanguageResponse> {
    const response = await axios.get(route('languages'), {
        params: { type }
    });
    return response.data;
}

export default function useSupportedLanguages(type: ServiceType, method: Method) {
    const [isLoading, setIsLoading] = useState(true);
    const [languageOptions, setLanguageOptions] = useState<LanguageResponse>(() => {
        const initial: Partial<LanguageResponse> = {
            common: []
        };

        [...Object.values(ExtractionMethod), ...Object.values(TranslationMethod)].forEach(m => {
            initial[m] = [];
        });

        return initial as LanguageResponse;
    });
    const [languageOptionsDict, setLanguageOptionsDict] = useState<LanguageOption[]>([]);

    useEffect(() => {
        setLanguageOptionsDict(mapCodesToDict(languageOptions, method));
    }, [method, languageOptions]);

    function mapCodesToDict(response: LanguageResponse, currentMethod: Method) {
        return [...response.common, ...response[currentMethod]].map((code) => ({
            label: getLanguageName(code),
            value: code
        })).sort(({label: labelA, value: codeA}, {label: labelB, value: codeB}) => {
            if (codeA === DEFAULT_LANGUAGE) return -1;
            if (codeB === DEFAULT_LANGUAGE) return 1;
            return labelA.localeCompare(labelB);
        });
    }

    useEffect(() => {
        getSupportedLanguages(type)
            .then(res => setLanguageOptions(res))
            .then(() => setIsLoading(false))
            .catch(() => toast.error('Failed to load languages'));
    }, [type]);

    return [languageOptionsDict, isLoading] as const;
}
