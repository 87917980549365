import React, {useCallback, useEffect, useRef} from 'react';

interface FrameMaskProps {
    imageHeight: number;
    imageWidth: number;
    state: {
        xStart: number,
        yStart: number,
        width: number,
        height: number
    };
    onChange: (state: {
        xStart: number,
        yStart: number,
        width: number,
        height: number
    }) => void;
}

const MIN_HEIGHT = 10;
const HEIGHT_FRACTION = 0.3;
const MAX_HEIGHT_FRACTION = 0.5;
const MIN_WIDTH_FRACTION = 0.33;

const FrameMask: React.FC<FrameMaskProps> = ({imageHeight, imageWidth, state, onChange}) => {
    const {xStart, yStart, width, height} = state;
    const isTouch = useRef(false);

    useEffect(() => {
        if (imageHeight > 0 && state.height === 0) {
            const newHeight = Math.min(imageHeight * HEIGHT_FRACTION, imageHeight * MAX_HEIGHT_FRACTION);
            const newYStart = imageHeight - newHeight;
            onChange({xStart: 0, yStart: newYStart, width: imageWidth, height: newHeight});
        }
    }, [imageHeight, imageWidth, onChange, state.height]);

    const handleStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
        if (e.type === 'mousedown' && isTouch.current) return;
        isTouch.current = e.type === 'touchstart';

        e.preventDefault();
        const startX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const startY = 'touches' in e ? e.touches[0].clientY : e.clientY;
        const startXPosition = xStart;
        const startYPosition = yStart;

        const handleMove = (e: MouseEvent | TouchEvent) => {
            if (e.type === 'mousemove' && isTouch.current) return;
            const currentX = 'touches' in e ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;
            const currentY = 'touches' in e ? (e as TouchEvent).touches[0].clientY : (e as MouseEvent).clientY;

            const deltaX = currentX - startX;
            const deltaY = currentY - startY;

            const newX = Math.max(0, Math.min(imageWidth - width, startXPosition + deltaX));
            const newY = Math.max(0, Math.min(imageHeight - height, startYPosition + deltaY));

            onChange({xStart: newX, yStart: newY, width, height});
        };

        const handleEnd = () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('touchmove', handleMove);
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchend', handleEnd);
            isTouch.current = false;
        };

        document.addEventListener('mousemove', handleMove);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchend', handleEnd);
    }, [xStart, yStart, width, height, imageWidth, imageHeight, onChange]);

    const handleResizeVertical = useCallback((position: 'top' | 'bottom') => (e: React.MouseEvent | React.TouchEvent) => {
        if (e.type === 'mousedown' && isTouch.current) return;
        isTouch.current = e.type === 'touchstart';

        e.preventDefault();
        e.stopPropagation();
        const startY = 'touches' in e ? e.touches[0].clientY : e.clientY;
        const startHeight = height;
        const startYPosition = yStart;

        const handleMove = (e: MouseEvent | TouchEvent) => {
            if (e.type === 'mousemove' && isTouch.current) return;
            const currentY = 'touches' in e ? (e as TouchEvent).touches[0].clientY : (e as MouseEvent).clientY;
            const deltaY = currentY - startY;

            if (position === 'top') {
                const maxHeight = Math.min(startYPosition + startHeight, imageHeight * MAX_HEIGHT_FRACTION);
                const newHeight = Math.max(MIN_HEIGHT, Math.min(startHeight - deltaY, maxHeight));
                const newPosition = startYPosition + startHeight - newHeight;
                onChange({xStart, yStart: newPosition, width, height: newHeight});
            } else {
                const newHeight = Math.max(MIN_HEIGHT, Math.min(startHeight + deltaY, imageHeight - startYPosition, imageHeight * MAX_HEIGHT_FRACTION));
                onChange({xStart, yStart: startYPosition, width, height: newHeight});
            }
        };

        const handleEnd = () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('touchmove', handleMove);
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchend', handleEnd);
            isTouch.current = false;
        };

        document.addEventListener('mousemove', handleMove);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchend', handleEnd);
    }, [height, yStart, xStart, width, imageHeight, onChange]);

    const handleResizeHorizontal = useCallback((side: 'left' | 'right') => (e: React.MouseEvent | React.TouchEvent) => {
        if (e.type === 'mousedown' && isTouch.current) return;
        isTouch.current = e.type === 'touchstart';

        e.preventDefault();
        e.stopPropagation();
        const startX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const startWidth = width;
        const startXPosition = xStart;

        const handleMove = (e: MouseEvent | TouchEvent) => {
            if (e.type === 'mousemove' && isTouch.current) return;
            const currentX = 'touches' in e ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).clientX;
            const deltaX = currentX - startX;

            const minWidth = imageWidth * MIN_WIDTH_FRACTION;

            if (side === 'left') {
                // Corrected logic for left resize
                const maxWidth = startXPosition + startWidth; // Maximum width extends to original right edge
                const newWidth = Math.max(minWidth, Math.min(startWidth - deltaX, maxWidth));
                const newX = startXPosition + (startWidth - newWidth);
                onChange({xStart: newX, yStart, width: newWidth, height});
            } else {
                // Right resize remains the same
                const newWidth = Math.max(minWidth, Math.min(startWidth + deltaX, imageWidth - startXPosition));
                onChange({xStart: startXPosition, yStart, width: newWidth, height});
            }
        };

        const handleEnd = () => {
            document.removeEventListener('mousemove', handleMove);
            document.removeEventListener('touchmove', handleMove);
            document.removeEventListener('mouseup', handleEnd);
            document.removeEventListener('touchend', handleEnd);
            isTouch.current = false;
        };

        document.addEventListener('mousemove', handleMove);
        document.addEventListener('touchmove', handleMove);
        document.addEventListener('mouseup', handleEnd);
        document.addEventListener('touchend', handleEnd);
    }, [width, xStart, yStart, height, imageWidth, onChange]);

    return (
        <div
            style={{
                position: 'absolute',
                left: xStart,
                top: yStart,
                width: width,
                height: height,
                backgroundColor: 'rgba(0, 255, 0, 0.3)',
                border: '1px solid rgba(0, 255, 0, 0.5)',
                cursor: 'move',
                touchAction: 'none',
            }}
            onMouseDown={handleStart}
            onTouchStart={handleStart}
        >
            <div
                style={{
                    position: 'absolute',
                    top: -5,
                    left: 0,
                    right: 0,
                    height: '10px',
                    cursor: 'ns-resize',
                }}
                onMouseDown={handleResizeVertical('top')}
                onTouchStart={handleResizeVertical('top')}
            />
            <div
                style={{
                    position: 'absolute',
                    bottom: -5,
                    left: 0,
                    right: 0,
                    height: '10px',
                    cursor: 'ns-resize',
                }}
                onMouseDown={handleResizeVertical('bottom')}
                onTouchStart={handleResizeVertical('bottom')}
            />
            <div
                style={{
                    position: 'absolute',
                    left: -5,
                    top: 0,
                    bottom: 0,
                    width: '10px',
                    cursor: 'ew-resize',
                }}
                onMouseDown={handleResizeHorizontal('left')}
                onTouchStart={handleResizeHorizontal('left')}
            />
            <div
                style={{
                    position: 'absolute',
                    right: -5,
                    top: 0,
                    bottom: 0,
                    width: '10px',
                    cursor: 'ew-resize',
                }}
                onMouseDown={handleResizeHorizontal('right')}
                onTouchStart={handleResizeHorizontal('right')}
            />
        </div>
    );
};

export default FrameMask;
