import Select from "react-select";
import useSupportedLanguages from "@/hooks/useSupportedLanguages";
import {TranslationMethod} from "@/types/enums";
import {getLanguageName} from "@/utils/textFormatting";
import {useEffect} from "react";

export type TranslateModalProps = {
    onSelect: (language: string) => void;
    selectedLanguage: string;
    method: TranslationMethod;
}

const customStyles = {
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999
    })
};

export default function ({onSelect, selectedLanguage, method }: TranslateModalProps) {
    const [languageOptions, isLoadingLang] = useSupportedLanguages('translation', method);
    const initialValue = selectedLanguage ?
        {
            label: getLanguageName(selectedLanguage),
            value: selectedLanguage
        } : undefined;

    if(isLoadingLang) {
        return null;
    }

    return (
        <div className="goog-te-gadget" dir="ltr" style={{}}>
            <div id=":0.targetLanguage">
                <Select
                    placeholder={'Select language'}
                    menuPortalTarget={document.body ? document.body : undefined}
                    menuPlacement="top"
                    styles={customStyles}
                    defaultValue={initialValue}
                    inputId="lang-input"
                    onChange={(e) => onSelect(e!.value)}
                    options={languageOptions}/>
            </div>
        </div>

    )
}
