import React, {useEffect, useMemo, useState} from "react";
import {PersistedJob, Subtitle} from "@/types";
import Spinner from "@/Components/Spinner";
import PrimaryButton from "@/Components/PrimaryButton";
import LanguageWidget from "@/Pages/Dashboard/Translate/LanguageWidget";
import {translate} from "@/services/translate/google/GoogleTranslateService";
import {translate as aiTranslate} from "@/services/translate/AI/AiTranslateService";
import toast from "react-hot-toast";
import {AnalyticsEvent, sendEvent} from "@/services/analytics";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faRightLeft} from "@fortawesome/free-solid-svg-icons";
import {TranslationMethod} from "@/types/enums";
import Popover from "@/Components/Popover";
import AiTranslateInfoModal from "@/Pages/Dashboard/Translate/AiTranslateInfoModal";
import {formatMinutes, getLanguageName} from "@/utils/textFormatting";
import GoogleIcon from "@/Components/Icons/GoogleIcon";
import AiIcon from "@/Components/Icons/AiIcon";
import ProgressBar from "@/Components/ProgressBar";
import InfoIcon from "@/Components/Icons/InfoIcon";
import PurchaseModal from "@/Pages/Dashboard/Purchase/PurchaseModal";
import InsufficientCreditsException from "@/exceptions/InsufficientCreditsException";
import {MultiViewModal} from "@/Components/MultiViewModal";
import {addTranslation, getSubtitles} from "@/services/subtitleService";
import {useConfirm} from "@/Components/ConfirmDialog";
import useSupportedLanguages from "@/hooks/useSupportedLanguages";

export type TranslateModalProps = {
    show: boolean;
    onClose: (inProgressLanguage?: string) => void;
    onTranslateComplete: (jobIds: string[], language: string, method: TranslationMethod) => void;
    translatedLanguages: string[];
    currentLanguage: string | null;
    jobs: PersistedJob[];
    isFreeTrial: boolean;
    subtitles?: Record<string, Subtitle[]>
};

type Store = {
    selectedLanguage: string;
    method: TranslationMethod;
}

const STORE_KEY = 'translate-modal';

export default function TranslateModal({
    show,
    onClose,
    translatedLanguages,
    onTranslateComplete,
    currentLanguage,
    subtitles,
    jobs,
    isFreeTrial
}: TranslateModalProps) {
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [isTranslating, setIsTranslating] = useState(false);
    const [method, setMethod] = useState<TranslationMethod>(TranslationMethod.GOOGLE);
    const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);
    const [showCreditPurchaseModal, setShowCreditPurchaseModal] = useState(false);
    const [creditBalance, setCreditBalance] = useState(0);
    const [progress, setProgress] = useState<Record<string, number>>({});
    const [currentView, setCurrentView] = useState(0);
    const languageName = getLanguageName(selectedLanguage);
    const [jobSubtitles, setJobSubtitles] = useState<Record<string, Subtitle[]>>({});
    const totalMinutes = jobs.reduce((acc, job) => acc + job.file_duration / 60, 0);
    const totalProgress = Object.values(progress).reduce((acc, val) => acc + val, 0) / jobs.length;
    const confirm = useConfirm()
    const isSubtitlesLoaded = Object.keys(jobSubtitles).length === jobs.length;

    useEffect(() => {
        const prevState = localStorage.getItem(STORE_KEY);
        if (prevState) {
            const {selectedLanguage: prevLang, method: prevMethod} = JSON.parse(prevState) as Store;
            setSelectedLanguage(prevLang);
            setMethod(prevMethod);
        }
    }, []);

    const loadSubtitles = async () => {
        const subtitles = await Promise.all(
            jobs.map(job => getSubtitles(job.id))
        );
        const subtitlesWithIds = jobs.map((job, index) => [job.id, subtitles[index]]);
        setJobSubtitles(Object.fromEntries(subtitlesWithIds));
    }

    useEffect(() => {
        if (show) {
            setIsTranslating(false);
            setProgress({});
        } else {
            setJobSubtitles({});
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            if (!subtitles) {
                loadSubtitles().catch(e => toast.error('Failed to load subtitles'))
            } else {
                setJobSubtitles(subtitles);
            }
        }
    }, [show]);

    useEffect(() => {
        if (selectedLanguage) {
            const state: Store = {selectedLanguage, method};
            localStorage.setItem(STORE_KEY, JSON.stringify(state));
        }
    }, [selectedLanguage, method]);

    useEffect(() => {
        if (isTranslating) {
            sendEvent(AnalyticsEvent.TRANSLATE, {
                language_code: selectedLanguage,
                language_name: languageName,
                method
            });
        }
    }, [isTranslating]);

    useEffect(() => {
        if (showMoreInfoModal) {
            sendEvent(AnalyticsEvent.VIEW_AI_TRANSLATE_INFO, {
                language_name: languageName,
            });
        }
    }, [showMoreInfoModal]);

    async function handleClose() {
        if (!isTranslating || await confirm({ message: 'Are you sure you want to exit?'})) {
            setIsTranslating(false);
            setTimeout(() => setCurrentView(0), 250);
            onClose(isTranslating && method === TranslationMethod.AI ? languageName : undefined);
        }
    }

    async function runTranslate() {
        if (translatedLanguages.includes(languageName.toLowerCase())) {
            if (!await confirm({
                message: 'You have already translated this language. Do you want to overwrite it?',
                title: 'Overwrite Translation'
            })) {
                return;
            }
        }
        const successIds = [];
        setIsTranslating(true);
        for (const job of jobs) {
            try {
                if (method === TranslationMethod.GOOGLE) {
                    await execGoogleTranslation(job);
                } else {
                    await execAiTranslation(job);
                }
                successIds.push(job.id);
            } catch (e: any) {
                if (e instanceof InsufficientCreditsException) {
                    setCreditBalance(e.currentBalance);
                    setShowCreditPurchaseModal(true);
                    break;
                } else {
                    toast.error(e.message || 'Failed to translate');
                }
            }
        }

        onTranslateComplete(successIds, selectedLanguage, method);
        setIsTranslating(false);
        setProgress({});
    }

    async function execGoogleTranslation(job: PersistedJob) {
        const subtitles = jobSubtitles[job.id];
        const results = await translate(subtitles.map((subtitle) => subtitle.text), selectedLanguage);
        const updatedSubtitles = subtitles!.map((subtitle, index) => ({
            ...subtitle,
            text: results[index] || subtitle.text
        }));
        await addTranslation(job.id, updatedSubtitles, selectedLanguage);
    }

    async function execAiTranslation(job: PersistedJob) {
        return aiTranslate(
            job,
            currentLanguage,
            selectedLanguage,
            (progress) => {
                setProgress(prev => ({...prev, [job.id]: progress}));
            }
        );
    }

    const MethodSelectionView = useMemo(() => (
        <div className="px-7" title="Select Translation Method">
            <label id="aria-label" htmlFor="lang-input" className="text-sm">Translation Method</label>
            <div className="flex w-full mt-2">
                <button type="button"
                        onClick={() => setMethod(TranslationMethod.GOOGLE)}
                        className={`px-4 grow py-5 cursor-pointer group text-sm font-medium flex flex-col items-center flex-1
                    border-2 rounded  hover:bg-gray-100 hover:text-blue-700 ${method === TranslationMethod.GOOGLE ? 'border-blue-600 text-blue-600 ' : 'bg-white border-gray-200 text-gray-900'}`}>
                    <GoogleIcon className="h-5 w-5 mb-1.5"/>
                    <span className="font-bold text-md mb-0.5">Google Translate</span>
                    <span
                        className="bg-gray-100 group-hover:bg-gray-200 text-gray-800 text-xs font-medium mt-1 px-2.5 py-0.5 rounded">Free</span>
                </button>
                <div className="w-3"></div>
                <Popover
                    delay={0}
                    trigger="hover"
                    placement="top"
                    aria-labelledby="default-popover"
                    content={
                        <div className="w-72 text-sm text-gray-500 dark:text-gray-400">
                            <div className="px-3 py-2">
                                <p>Get more accurate and natural sounding translations using AI.</p>
                                <span
                                    onClick={() => setShowMoreInfoModal(true)}
                                    className="text-blue-600 inline-block mt-2 font-bold cursor-pointer hover:underline">More Info</span>
                            </div>
                        </div>
                    }
                >
                    <button type="button"
                            onClick={() => setMethod(TranslationMethod.AI)}
                            className={`px-4 grow py-5 text-sm font-medium flex flex-col items-center flex-1 cursor-pointer
                        border-2 rounded  hover:bg-gray-100 hover:text-blue-700 ${method === TranslationMethod.AI ? 'border-blue-600 text-blue-600 ' : 'bg-white border-gray-200 text-gray-900'}`}>
                        <AiIcon className="h-5 w-5 text-orange-400 mb-1.5"/>
                        <span className="font-bold text-md mb-0.5">Translate with AI</span>
                        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mt-1  px-2.5 py-0.5 rounded">Superior Quality</span>
                    </button>
                </Popover>
            </div>
            <div className="pt-2 md:py-7 grow google-element w-full">
                <label id="aria-label" htmlFor="lang-input" className="text-sm mb-2 inline-block">Translation
                    Language</label>
                <LanguageWidget
                    onSelect={setSelectedLanguage}
                    selectedLanguage={selectedLanguage}
                    method={method}
                />
            </div>
            <div className="mt-4 md:mt-0 mb-4 w-full ">
                {method === TranslationMethod.GOOGLE ? (
                    <PrimaryButton
                        disabled={isTranslating || !selectedLanguage || !isSubtitlesLoaded}
                        className={'w-full mb-4'}
                        onClick={runTranslate}
                    >
                        <FontAwesomeIcon
                            className="text-white  w-3 h-3 pr-2 align-middle "
                            icon={faRightLeft}/>
                        <span>Translate</span> {isTranslating &&
                        <Spinner className="ml-2 align-middle inline-block"/>}
                    </PrimaryButton>
                ) : (
                    <Popover arrowClassName="!bg-gray-50" placement="top" trigger="hover" disabled={!isFreeTrial}
                             content={<FreeTrialPopover/>}>
                        <div>
                            <PrimaryButton
                                disabled={!selectedLanguage || !isSubtitlesLoaded || isFreeTrial}
                                className={'w-full mb-4'}
                                onClick={() => setCurrentView(1)}
                            >
                                <span>Continue</span>
                                <FontAwesomeIcon className="text-white w-3 h-3 pl-2 align-middle" icon={faArrowRight}/>
                            </PrimaryButton>
                        </div>
                    </Popover>
                )}
            </div>
        </div>
    ), [
        selectedLanguage,
        isTranslating,
        method,
        isSubtitlesLoaded,
        showMoreInfoModal,
        creditBalance
    ]);

    const ConfirmationView = useMemo(() => (
        <div className="flex flex-col h-full">
            {
                !isTranslating && (<p className="text-center text-gray-500 text-base -mt-4 md:-mt-6 lg:-mt-7">
                    Review and confirm your request
                </p>)
            }
            <div className="flex-grow px-7 flex flex-col">
                {!isTranslating ? (
                    <div className=" h-full justify-center flex-col flex">
                        <div className="border-b text-sm pb-3 mb-3 px-3">
                            <div className="mb-1 flex items-center">
                                <span className="text-gray-500 text-base">Credits Used</span>
                                <Popover
                                    delay={0}
                                    trigger="hover"
                                    placement="bottom"
                                    content={
                                        <div className="w-72 text-sm text-gray-500 dark:text-gray-400 p-4">
                                            <p>Each minute of video translation costs half an extraction minute. For
                                                example,
                                                translating a 60-minute video uses 30 credits.</p>
                                        </div>
                                    }
                                >
                                    <div><InfoIcon
                                        className="text-gray-300 ml-1 cursor-pointer hover:text-blue-700 !w-5 !h-5"/>
                                    </div>
                                </Popover>
                            </div>
                            <span
                                className="font-bold ">{formatMinutes(Math.max(1, Math.round(totalMinutes / 2)))}</span>
                        </div>
                        <div className="border-b pb-3 mb-3 px-3 text-sm">
                            <span className="text-gray-500 mb-1 block text-base">Language Selected</span>
                            <span className="font-bold ">{languageName}</span>
                        </div>
                        <div className="px-3 text-sm">
                            <span className="text-gray-500 mb-1 block text-base">Estimated Processing Time</span>
                            <span className="font-bold">{`${jobs.length > 1 ? '1-5' : '1-3'}`} Minutes</span>
                        </div>
                    </div>
                ) : (
                    <div className=" h-full justify-center flex-col flex">
                        <p className="text-center mb-4 font-bold">Translation in progress</p>
                        <ProgressBar max={1} progress={totalProgress} height="md" color="blue"/>
                        <span
                            className="text-gray-500 mt-3 text-center">{(totalProgress * 100).toFixed()}% Complete</span>
                    </div>
                )}
                <div className=" mb-4">
                    <PrimaryButton
                        disabled={!selectedLanguage || !isSubtitlesLoaded || isTranslating}
                        className={'w-full'}
                        onClick={runTranslate}
                    >
                        <FontAwesomeIcon className="text-white w-3 h-3 pr-2 align-middle" icon={faRightLeft}/>
                        <span>Translate</span>
                        {isTranslating && <Spinner className="ml-2 align-middle inline-block"/>}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    ), [isTranslating, selectedLanguage, totalProgress, languageName, isSubtitlesLoaded]);

    return (
        <MultiViewModal
            currentIdx={currentView}
            onBack={() => setCurrentView(0)}
            showBackButton={!isTranslating}
            title="Translate Subtitles"
            maxWidth={'lg'}
            show={show} onClose={handleClose}
            extras={
                <>
                    <AiTranslateInfoModal show={showMoreInfoModal} onClose={() => setShowMoreInfoModal(false)}/>
                    <PurchaseModal
                        show={showCreditPurchaseModal}
                        onCompleted={async () => {
                            setShowCreditPurchaseModal(false);
                            await runTranslate();
                        }}
                        message={`You have ${formatMinutes(creditBalance)} of extraction credits remaining, but need ${formatMinutes(totalMinutes / 2)} to translate this video.`}
                        onClose={() => setShowCreditPurchaseModal(false)}
                    />
                </>
            }
        >
            {MethodSelectionView}
            {ConfirmationView}
        </MultiViewModal>
    );
}

function FreeTrialPopover() {
    return (
        <div className="px-4 pt-2 w-72 bg-gray-50">
            <span
                className="inline-block mb-3 text-sm">Upgrade to a paid account by purchasing credits to unlock premium features.</span>
        </div>
    )
}
