import { PageProps } from "@/types";
import PageLayout from "@/Layouts/PageLayout";
import { Head, Link, usePage } from "@inertiajs/react";
import { faCircleQuestion, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export type SupportLayoutProps = {
    children: React.ReactNode
}

export default function({ children }: SupportLayoutProps) {
    const { auth: { user } } = usePage<PageProps>().props;

    return (
        <>
            <Head title="Support">
                <meta name="description" content="Get help and support with our services." />
            </Head>
            <PageLayout header={auth => auth ?
                <h1 className="font-semibold text-xl text-gray-800 leading-tight">Support</h1> : null}>
                {
                    (authenticated) => (
                        <div className="container mx-auto lg:flex">
                            <aside id="sidebar"
                                   className="w-full lg:w-1/5 px-2 lg:px-0 lg:ml-6 lg:border-r lg:pr-5 mt-3 lg:mt-10">
                                <ul className="flex lg:flex-col py-1 list-unstyled fw-normal small lg:overflow-visible overflow-x-auto px-2 lg:px-0 space-x-2 lg:space-x-0 lg:space-y-2">
                                    <li className="flex-shrink-0">
                                        <Link
                                            href={route('faqs')}
                                            className={`flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('faqs') ? (user ? 'bg-white' : 'bg-gray-100') : ''}`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className={route().current('faqs') ? 'text-blue-500' : 'text-gray-500'}
                                            />
                                            <span className="ml-3">FAQs</span>
                                        </Link>
                                    </li>
                                    <li className="flex-shrink-0">
                                        <Link
                                            href={route('contact')}
                                            className={`flex items-center p-2 text-base text-gray-900 rounded-lg hover:bg-gray-200 group ${route().current('contact') ? (user ? 'bg-white' : 'bg-gray-100') : ''}`}
                                        >
                                            <FontAwesomeIcon
                                                icon={faMessage}
                                                className={route().current('contact') ? 'text-blue-500' : 'text-gray-500'}
                                            />
                                            <span className="ml-3">Contact Us</span>
                                        </Link>
                                    </li>
                                </ul>
                            </aside>
                            <hr className="lg:hidden mt-3"/>
                            <div className={`min-h-[70vh] w-full lg:w-4/5 lg:flex-col ${!authenticated ? 'mt-10' : ''}`}>
                                {children}
                            </div>
                        </div>
                    )
                }
            </PageLayout>
        </>
    );
}
